<template>
  <div>
    <div class="vx-row">
        <div class="vx-col w-full">
            <!-- resumen del credito -->
            <table class="table-row full-border">
                <caption></caption>
                <tr class="vertical-top">
                    <!-- COL 1-->
                    <td class="w-50 p-l">
                        <table class="table-row-spaced">
                            <tbody>
                            <tr class="info-row">
                                <td class="irl bold">Título de simulación</td>
                                <td class="irv">{{clientName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>

                    <!-- COL 2-->
                    <td class="w-50 p-r">
                        <!-- FREE SPACE -->
                    </td>
                </tr>
            </table>
            <!-- fin resumen del credito -->
        </div>
    </div>

    <div class="vx-row mt-4">
        <div class="vx-col w-full">

            <!-- resumen del credito -->
            <table class="table-row full-border">
                <tr class="vertical-top">
                    <!-- COL 1-->
                    <td class="w-50 p-l">
                        <div class="title">El resumen de tu crédito.</div>
                        <table class="table-row-spaced">
                            <tbody>
                            <tr class="info-row">
                                <td class="irl bold">Monto del financiamiento</td>
                                <td class="irv">${{ formatPrice(simulation.finance.total_cost) }}</td>
                            </tr>
                            <!--
                            <tr class="info-row shaded">
                                <td class="irl bold">+ Intereses Financiados</td>
                                <td class="irv">${{ formatPrice(simulation.finance.financed_interests) }}</td>
                            </tr>
                            <tr class="info-row dark">
                                <td class="irl bold">= Monto a financiar</td>
                                <td class="irv">${{ formatPrice(simulation.finance.financed_amount) }}</td>
                            </tr>-->
                            <tr class="info-row">
                                <td class="irl bold">Plazo (Meses)</td>
                                <td class="irv">{{ simulation.finance.term }}</td>
                            </tr>
                            <tr class="info-row">
                                <td class="irl bold">Tasa Anual</td>
                                <td class="irv">{{ simulation.finance.annual_interest_percentage|convertPercentage }}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>

                    <!-- COL 2-->
                    <td class="w-50 p-r">
                        <div class="title">Tus pagos.</div>
                        <table class="table-row-spaced">
                            <tbody>
                            <tr class="info-row">
                                <td class="irl bold">Comisión por gastos de pre-originación</td>
                                <td class="irv">${{ formatPrice(simulation.finance.openning_comission) }}</td>
                            </tr>
                            <!--<tr class="info-row">
                                <td class="irl bold">+ Enganche ({{simulation.finance.deposit_percentage * 100}}%)</td>
                                <td class="irv">${{ formatPrice(simulation.finance.deposit) }}</td>
                            </tr>
                            <tr class="info-row dark">
                                <td class="irl bold">= Pago Inicial <span v-if="simulation.finance.delivery_term > 0">(1 Pago)</span></td>
                                <td class="irv">${{ formatPrice(simulation.finance.initial_payment) }}</td>
                            </tr>-->
                            <tr class="info-row high" v-if="simulation.finance.delivery_term > 0">
                                <td class="irl bold">Pago Entrega ({{ simulation.finance.delivery_term }} Pagos)</td>
                                <td class="irv">${{ formatPrice(simulation.finance.symbolic_payment) }}</td>
                            </tr>
                            <tr class="info-row high">
                                <td class="irl bold">Pago Mensual ({{ simulation.finance.term - 1 }} Pagos)</td>
                                <td class="irv">${{ formatPrice(simulation.finance.monthly_payment) }}</td>
                            </tr>
                            <tr class="info-row high">
                                <td class="irl bold">Pago balloon (Último pago)</td>
                                <td class="irv">${{ formatPrice(ballonPayment) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
            <!-- fin resumen del credito -->
        </div>
    </div>

    <div class="mt-8 mb-4">
      <vs-button
        icon-pack="feather"
        icon="icon-download-cloud"
        @click="$emit('onDownloadPdfPress', {})"
      >
          Descargar
      </vs-button>
    </div>

    <table class="table-dynamic" aria-describedby="pagos">
        <thead>
          <tr class="table-title">
              <th colspan="8">
                <div class="title pb-3 text-left">Plan de Pagos.</div>
              </th>
          </tr>
          <tr class="table-cols">
              <th>No. pago</th>
              <th>Pago</th>
              <!--<th>Pago solicitante</th>
              <th>Pago crédito</th>-->
              <th>Saldo Inicial</th>
              <th>Amortización</th>
              <th>Interés</th>
              <th>Comisión</th>
              <th>IVA</th>
              <th>Saldo Final</th>
          </tr>
        </thead>
        <tbody>
          <tr class="f-12" v-for="amortization in simulation.amortization" :key="amortization.number">
            <td class="text-center">{{amortization.number}}</td>
            <td class="text-center">$ {{formatPrice(amortization.payment)}}</td>
            <!--<td class="text-center">$ {{formatPrice(amortization.cash_payment)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.capital_payment)}}</td>-->
            <td class="text-center">$ {{formatPrice(amortization.initial_debt)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.amortization)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.interest)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.client_comission)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.client_commission_tax + amortization.tax)}}</td>
            <td class="text-center">$ {{formatPrice(amortization.final_debt)}}</td>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import {formatPrice} from "../../../../filters";
import _ from "lodash";

export default {
    name: "CreditWorkSimulatorResume",
    props: ["simulation", "clientName"],
    methods: {
        formatPrice
    },
    computed: {
      ballonPayment() {
        return _.last(
          _.sortBy(this.simulation.amortization, a => a.number)
        ).payment
      }
    },
    filters: {
        convertPercentage(val) {
            return (val * 100).toFixed(2);
        }
    }
}
</script>

<style scoped>
.table-dynamic thead tr.table-title th {
  padding: 0px !important;
}
.table-cols th {
    text-align: center;
}
.table-row {
    width: 100%;
    border-collapse: collapse;
    display: table !important;
}
.table-row-spaced {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 5px;
}
.w-50 {
    width: 50%;
}
.p-l {
    padding-right: 10px;
}
.p-r {
    padding-left: 10px;
}
.vertical-top {
    vertical-align: top;
}
.title {
    font-size: 18px;
    font-weight: normal;
    font-family: "gilroybold";
}
.info-row {
    width: 100%;
    font-family: "gilroybold";
    /*  font-size: 12px; */
}
.info-row > td {
    height: 35px;
}
.info-row.high {
    font-weight: bold;
    color: #28DE18;
}
.info-row.shaded {
    background-color: #ccc;
    color: #000;
}
.info-row.dark {
    background-color: #313131;
    color: #fff;
}
.irl {
    padding-left: 6px;
    text-align: left;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-top-color: transparent;
}
.irv {
    padding-right: 6px;
    text-align: right;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-top-color: transparent;
}
.full-border .irl {
    padding-left: 6px;
    text-align: left;
    border-width: 2px 0 2px 2px;
    border-style: solid;
    border-color: #000;
}
.full-border .irv {
    padding-right: 6px;
    text-align: right;
    border-width: 2px 2px 2px 0;
    border-style: solid;
    border-color: #000;
    border-top-color: #000;
}
.strong .irl {
    border-width: 1px 0 2px 0px;
    border-bottom-color: #000;
}
.strong .irv {
    border-width: 1px 0px 2px 0;
    border-bottom-color: #000;
}
.full-border .strong .irl {
    border-width: 1px 0 2px 1px;
    border-color: #000;
}
.full-border .strong .irv {
    border-width: 1px 1px 2px 0;
    border-color: #000;
}
.high .irl {
    border-width: 2px 0 2px 2px;
    border-color: #28DE18;
}
.high .irv {
    border-width: 2px 2px 2px 0;
    border-color: #28DE18;
}
.shaded .irl, .shaded .irv {
    border-color: #ccc;
}
.colored .irl, .colored .irv {
    border-color: #28DE18;
}
.dark .irl, .dark .irv {
    border-color: #313131;
}
.table-dynamic {
    width: 100%;
    border-collapse: collapse;
}
.table-dynamic .table-cols {
    background-color: #28DE18;
    font-weight: normal;
    font-family: 'gilroybold';
    color: #fff;
    text-align: center;
}
.table-dynamic .table-cols th {
    font-weight: bold;
    font-size: 14px;
    height: 30px;
    vertical-align: middle;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.table-dynamic .table-cols th:first-child {
    border-left: 1px solid #000;
}
.table-dynamic .table-cols th:last-child {
    border-right: 1px solid #000;
}
.table-dynamic > tbody {
    border: 1px solid #000;
}
.table-dynamic > tbody > tr {
    vertical-align: middle;
}
.table-dynamic > tbody > tr > td {
    border-bottom: 1px solid #000;
    padding-top: 5px;
    padding-bottom: 5px;
}
.table-dynamic.movements > tbody > tr > td {
    border-bottom: 1px solid #ccc;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 9px;
}
.table-dynamic.movements tbody tr:last-child td {
    border-bottom: 1px solid #000;
}
.table-dynamic.movements > tbody > tr > td.empty {
    font-size: 14px;
    text-align: center;
    background-color: #e8e8e8
}
.table-dynamic .table-title {
    text-align: left;
}
</style>