export class CreditWorkSimulatorService {
    /**
     * @param {string} currency
     * @param {numeric|null} rate
     * @param {numeric} financedInterestPercentage
     * @param {numeric|null} totalCost
     * @param {numeric|null} totalCostUsd
     * @param {numeric} deliveryTerm
     * @param {numeric} depositPercentage
     * @param {numeric} term
     * @param {numeric} qualification
     * @param {numeric} loanType
     * @param {numeric} surplusPercentage
     * @param {numeric} capitalPaidAtTerm
     * @param {boolean} spreadPortfolio
     * @param {string} paymentPeriodicity
     * @param {numeric} crowdfundingLoanType
     * @returns {Promise<AxiosResponse<any>>}
     */
    static simulate(
        currency,
        rate,
        financedInterestPercentage,
        totalCost,
        totalCostUsd,
        deliveryTerm,
        depositPercentage,
        term,
        qualification,
        loanType,
        surplusPercentage,
        capitalPaidAtTerm,
        spreadPortfolio,
        paymentPeriodicity,
        crowdfundingLoanType
    ) {
        return axios.post('/api/loan/finance/simulate', {
            finance_product_id: 3,
            currency: currency,
            rate: rate,
            financed_interest_percentage: financedInterestPercentage,
            total_cost: totalCost,
            total_cost_usd: currency === 'usd' ? totalCostUsd : totalCost,
            delivery_term: deliveryTerm,
            deposit_percentage: depositPercentage,
            term: term,
            qualification: qualification,
            loan_type: loanType,
            surplus_percentage: surplusPercentage,
            capital_paid_at_term: capitalPaidAtTerm,
            payment_periodicity: paymentPeriodicity,
            spread_portfolio: spreadPortfolio,
            crowdfunding_loan_type: crowdfundingLoanType
        })
    }
}