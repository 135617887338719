<template>
  <div>
      <!-- simulador de credito de trabajo -->
    <vx-card class="mb-6" >
      <div class="vx-row">
        <div class="vx-col w-full">
          <h1 class="rg-big-title extrabold rg-title">
            Simulador <span class="orange">capital de trabajo</span>.
          </h1>
        </div>
      </div>
    </vx-card>

    <vx-card title="¡Vamos a simular!" class="mb-6">
      <p class="mb-5 black">
          Simula un crédito para tu empresa y juntos hagamos posible el Futuro Verde
      </p>
      <div class="vx-row">
        <div class="vx-col w-full">
         <credit-work-simulator-form @onSimulate="simulateHandler" />
        </div>
      </div>
    </vx-card>

    <vx-card title="Resultados de la simulación" v-if="simulation !== null">
      <div class="vx-row">
        <div class="vx-col w-full" >
          <credit-work-simulator-resume
            :client-name="defaultParams.clientName"
            :simulation="simulation"
            @onDownloadPdfPress="downloadPdf"
          />
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import CreditWorkSimulatorForm from "./CreditWorkSimulatorForm.vue";
import CreditWorkSimulatorResume from "./CreditWorkSimulatorResume.vue";
import {CreditWorkSimulatorService} from "@/bl/CreditWorkSimulatorService";

export default {
  name: "CreditWorkSimulator",
    components: {CreditWorkSimulatorResume, CreditWorkSimulatorForm},
  data: () => ({
    simulation: null,
    defaultParams: {
        clientName: null,
        currency: 'mxn',
        rate: 0,
        annualInterestPercentage: 0.1640,
        financedInterestPercentage: 0,
        totalCost: 0,
        totalCostUsd: null,
        deliveryTerm: 0,
        depositPercentage: 0,
        term: 12,
        qualification: 'B+',
        loanType: 4,
        surplusPercentage: 0,
        capitalPaymentPercentage: 0,
        spreadPortfolio: true,
        paymentPeriodicity: 'monthly',
        crowdfundingLoanType: 1
    }
  }),
  methods: {
    async simulateHandler(ev) {
      this.$vs.loading();
      try {
          this.defaultParams.clientName = ev.clientName;
          this.defaultParams.totalCost = ev.loanAmount;
          this.defaultParams.term = ev.term;
          this.defaultParams.capitalPaymentPercentage = ev.capitalPaymentPercentage;

          const {data} = await CreditWorkSimulatorService.simulate(
              this.defaultParams.currency,
              this.defaultParams.rate,
              this.defaultParams.financedInterestPercentage,
              this.defaultParams.totalCost,
              this.defaultParams.totalCostUsd,
              this.defaultParams.deliveryTerm,
              this.defaultParams.depositPercentage,
              this.defaultParams.term,
              this.defaultParams.qualification,
              this.defaultParams.loanType,
              this.defaultParams.surplusPercentage,
              this.defaultParams.capitalPaymentPercentage,
              this.defaultParams.spreadPortfolio,
              this.defaultParams.paymentPeriodicity,
              this.defaultParams.crowdfundingLoanType
          )

          this.simulation = data;
      } finally {
          this.$vs.loading.close();
      }
    },
    async downloadPdf() {
      this.$vs.loading();
      try {
        const response = await axios.post('/api/loan/finance/pdf', {
          finance_product_id: 3,
          currency: this.defaultParams.currency,
          rate: this.defaultParams.rate,
          financed_interest_percentage: this.defaultParams.financedInterestPercentage,
          total_cost: this.defaultParams.totalCost,
          total_cost_usd: this.defaultParams.currency === 'usd' ? this.defaultParams.totalCostUsd : this.defaultParams.totalCost,
          delivery_term: this.defaultParams.deliveryTerm,
          deposit_percentage: this.defaultParams.depositPercentage,
          term: this.defaultParams.term,
          qualification: this.defaultParams.qualification,
          loan_type: this.defaultParams.loanType,
          surplus_percentage: this.defaultParams.surplusPercentage,
          capital_paid_at_term: this.defaultParams.capitalPaymentPercentage,
          payment_periodicity: this.defaultParams.paymentPeriodicity,
          spread_portfolio: this.defaultParams.spreadPortfolio,
          crowdfunding_loan_type: this.defaultParams.crowdfundingLoanType
        })

        const downloadLink = document.createElement("a");
        downloadLink.href = response.data.pdf_base64;
        downloadLink.download = this.name !== '' ? 'Simulación ' + this.defaultParams.clientName + '.pdf' : 'Simulación.pdf'
        downloadLink.click();
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>