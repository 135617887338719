function filterBy (list, value) {
    return list.filter(function(user){
        return user.business_name.toLowerCase().indexOf(value) >= 0;
    });
};
function formatPrice (value) {
    let val = (value/1).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

function formatPriceProject (value) {
    let val = (value/1).toFixed(0)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

function parseValue(str) {
    const pattern = /\$|\,/ig;
    return parseInt(str.replace(pattern, ''))
}

function parseFloatValue(str) {
    const pattern = /\$|\,/ig;
    return parseFloat(str.replace(pattern, ''))
}

export {filterBy, formatPrice, parseFloatValue, parseValue, formatPriceProject}
