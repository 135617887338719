<template>
    <div class="grid grid-cols-3 gap-3">
      <vs-input
        @keyup.enter="simulateHandler"
        v-money="money"
        name="loanAmount"
        v-validate="'required|is_not:$0.00'"
        :danger="errors.has('loanAmount')"
        :danger-text="errors.first('loanAmount')"
        v-model="form.loanAmount"
        class="mt-4 w-full"
        label="Monto solicitado *"
    ></vs-input>

  <div class="mt-4">
      <label>Plazo (Meses) *</label>
      <v-select
        name="term"
        v-validate="'required'"
        v-model="form.term"
        :options="termOptions"
      ></v-select>
  </div>

    <div class="mt-4">
        <label class="vs-input--label">Pago balloon </label>
        <v-select
          name="capitalPayment"
          v-validate="'required'"
          v-model="form.capitalPaymentPercentage"
          :reduce="cp => cp.value"
          :options="capitalPaymentOptionAvailable"
        ></v-select>
        <vs-input-hint>% de capital a pagar en último mes</vs-input-hint>
    </div>
    <!-- Save Button -->
    <div class="vx-row mt-3">
      <div class="vx-col w-full">
        <div>
          <vs-button
            :disabled="errors.count() > 0"
            @click="simulateHandler"
            class="ml-auto mt-2"
          >
            Simular
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VMoney} from 'v-money'
import {mapState} from "vuex";
import VsInputHint from "../../../../components/vsInput/VsInputHint.vue";
export default {
    name: "CreditWorkSimulatorForm",
    components: {VsInputHint},
    directives: {money: VMoney},
    computed: {
      ...mapState("auth", ["user"]),
      capitalPaymentOptionAvailable() {
          if (this.form.term > 12) {
              return this.capitalPaymentOptions.filter((cpo) => cpo.value <= 0.50)
          }
          return this.capitalPaymentOptions;
      }
    },
    watch: {
      'form.term': function (val) {
          if (val > 12) {
              this.form.capitalPaymentPercentage = 0.50;
          } else {
              this.form.capitalPaymentPercentage = 1;
          }
      }
    },
    data: () => ({
        form: {
            clientName: null,
            loanAmount: null,
            term: 12,
            capitalPaymentPercentage: 1
        },
        termOptions: [12, 18, 24, 30, 36, 42, 48, 54, 60],
        capitalPaymentOptions: [
            {value: 0, label: '0%'},
            {value: 0.10, label: '10%'},
            {value: 0.20, label: '20%'},
            {value: 0.30, label: '30%'},
            {value: 0.40, label: '40%'},
            {value: 0.50, label: '50%'},
            {value: 0.60, label: '60%'},
            {value: 0.70, label: '70%'},
            {value: 0.80, label: '80%'},
            {value: 0.90, label: '90%'},
            {value: 1, label: '100%'},
        ],
        money: {
            decimal: '.',
            thousands: ',',
            prefix: '$',
            suffix: '',
            precision: 2,
            masked: false
        },
    }),
    mounted() {
        this.$validator.validate();
        this.form.clientName = this.user.supplier_name;
    },
    methods: {
        simulateHandler() {
            let formRaw = Object.assign({}, this.form);
            formRaw.loanAmount = parseInt(
                formRaw.loanAmount
                .replaceAll(",", "")
                .replaceAll("$", "")
            )

            this.$emit('onSimulate', formRaw)
        }
    }
}
</script>

<style scoped>
.question-mark {
    padding-right: 3px !important;
}
</style>